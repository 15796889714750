const DEFAULT_DEPARTEMENT = "Département de couverture"
const DEFAULT_CERTIFICATION = "Certification"
const DEFAULT_ACTIVITE = "Activité"

const PREPROD_URL = "https://pre-prod.leguideachats.com"
const PROD_URL = "https://leguideachats.com"

const HOST_URL = PREPROD_URL
const BASE_URL = HOST_URL+"/admin/wp-json"




let tracking_actions = {
  site: [],
  phone: [],
  mail: [],
  view: [],
  file: [],
  hasActions: false
}

let excludedRoles = "fournisseur"

export default {
  DEFAULT_DEPARTEMENT: DEFAULT_DEPARTEMENT,
  DEFAULT_CERTIFICATION: DEFAULT_CERTIFICATION,
  DEFAULT_ACTIVITE: DEFAULT_ACTIVITE,
  BASE_URL: BASE_URL,
  TRACKING_ACTIONS: tracking_actions,
  EXCLUDED_ROLES: excludedRoles,
  HOST_URL: HOST_URL,
  PROD_URL: PROD_URL,
  PREPROD_URL: PREPROD_URL
}


